/** @format */

//ALL CSS above please don't remove. Because it use for case override css from library

/** @format */

// @import "./common";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .switch-bg-image {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/></svg>");
  }

  details > summary::-webkit-details-marker,
  details > summary::marker {
    display: none;
  }

  .custom-scrollbar-10::-webkit-scrollbar {
    width: 10px;
  }

  .custom-vertical-scrollbar-4::-webkit-scrollbar {
    height: 4px;
  }
}

* {
  box-sizing: border-box;
  outline: none;
  --tw-ring-shadow: transparent !important;
}


html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #e4e4e8;
  overflow: hidden;
}

.bg,
canvas {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  cursor: grab;
  scroll-behavior: smooth;
}

body {
  background-color: #4e4d4b;
}

.page {
  background-color: #eee;
  /* background-image: url("https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg"); */
  min-height: 100vh;
  /* background-repeat: no-repeat;
  background-size: 100% 100%; */
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888850;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #55555550;
}

/* fade left animation */
.fade-left-enter {
  margin-left: -100%;
  opacity: 0;
}

.fade-left-enter-active {
  margin-left: 0;
  opacity: 1;
  transition: all 500ms linear;
}

.fade-left-exit {
  margin-left: 0;
  opacity: 1;
}

.fade-left-exit-active {
  margin-left: -100%;
  opacity: 0;
  transition: all 500ms linear;
}

/* fade right animation */
.fade-right-enter {
  right: -100%;
  opacity: 0;
}

.fade-right-enter-active {
  right: 0;
  opacity: 1;
  transition: all 500ms linear;
}

.fade-right-exit {
  right: 0;
  opacity: 1;
}

.fade-right-exit-active {
  right: -100%;
  opacity: 0;
  transition: all 500ms linear;
}

.rs-base.hide {
  display: none;
}

.linear-top {
  background: linear-gradient(180deg, #00000091 0%, rgba(0, 0, 0, 0) 80%);
}
.linear-bottom {
  background: linear-gradient(360deg, #00000091 0%, rgba(0, 0, 0, 0) 80%);
}
.hide-bottom {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.dat-gui-wrapper {
  .title {
    color: white;
  }
  select {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0 !important;
    color: black;
  }
  input {
    margin-top: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .dg.main {
    max-height: calc(100vh - 120px);
    overflow: hidden;
    overflow-y: auto;
  }
  .dg.main .close-button.close-bottom {
    position: relative;
  }
  .dg .property-name {
    width: 55%;
    white-space: nowrap;
  }
  .dg .c {
    width: 45%;
  }
}

.z-fancybox-9999 {
  z-index: 9999 !important;
}

.fancybox__container {
  --fancybox-zIndex: 1200;
}

.fancybox__backdrop {
  --fancybox-bg: black;
}

table.overflow-table {
  thead {
    display: block;
  }
  tbody {
    overflow: auto;
    display: block;
    width: 100%;
  }
  tr {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
}

//ALL CSS above please don't remove. Because it use for case override css from library
