/** @format */

//ALL CSS above please don't remove. Because it use for case override css from library

/**
 * /* Graphie
 *
 * @format
 */

@import url("https://use.typekit.net/hom6irb.css");

/* Josefin Sans */
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500&display=swap");
/* Philosopher */
@import url("https://use.typekit.net/xcu1jzs.css");

//Main font
@font-face {
  font-family: "Futura Pt";
  src: url("./fonts/Adobe/ACaslonPro-Regular.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura Pt";
  src: url("./fonts/Adobe/ACaslonPro-Semibold.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura Pt";
  src: url("./fonts/Adobe/ACaslonPro-Bold.otf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// Adobe Caslon Pro
@font-face {
  font-family: "Adobe Caslon Pro";
  src: url("./fonts/Adobe/ACaslonPro-Regular.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Adobe Caslon Pro";
  src: url("./fonts/Adobe/ACaslonPro-Semibold.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Adobe Caslon Pro";
  src: url("./fonts/Adobe/ACaslonPro-Bold.otf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/Proxima/Proxima-Nova-Font.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Domaine Sans Display";
  src: url("./fonts/Domaine Sans Display Regular Regular.ttf")
    format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("./fonts/Suisse Int'l.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("./fonts/SuisseIntl-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// @import "./common";

@import "~react-toastify/dist/ReactToastify.css";

// Pretty checkbox
@import "~pretty-checkbox/src/pretty-checkbox.scss";
@import "@fancyapps/ui/dist/fancybox/fancybox.css";

@keyframes fadeTop {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pretty.p-svg .state .svg {
  width: 15px;
  height: 15px;
}
.pretty .state label:before {
  width: 15px;
  height: 15px;
  border-color: #242422;
}
.pretty .state label::after {
  width: 15px;
  height: 15px;
  border-color: #242422;
}
.fancybox__content {
  width: 100% !important;
  height: 100% !important;
}

.break-words {
  overflow-wrap: break-word;
  word-break: break-word;
}

.wrap-fancy-close {
  position: absolute;
  z-index: 1055;
  top: 70px;
  right: 45px;
}

.wrap-btn-fancy {
  position: absolute;
  z-index: 1055;
  top: 850px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .wrap-slide-fancy {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 14px;
  }

  .fancy-dot {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #C7A446;
    cursor: pointer;
  }

  .fancy-dot.fancy-dot-o {
    background-color: transparent;
    border: 1.5px solid #C7A446;
  }
}

.pure-fancy-box > .fancybox__toolbar {
  display: none !important;
}

.pure-fancy-box .fancybox__slide {
  padding: 0px 0px 00px 0px !important;
}

.fancybox__footer {
  display: none;
}

.fancybox__nav {
  display: none;
}

.pure-fancy-box .fancybox__content {
  cursor: default !important;
}

.pure-fancy-box .fancybox__content {
  pointer-events: none !important; 
}

#panorama {
  /* Force GPU acceleration */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);

  /* Prevent reflows */
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  /* Add perspective */
  perspective: 1000px;
  -webkit-perspective: 1000px;

  /* Additional optimizations */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight */
}


.accordion__input:checked ~ .accordion__content {
  display: block;
}