  .StarButton .StarIcon {
    position: relative;
    transition: transform 100ms;
    stroke: #ababab;
  }
  .StarButton:active .StarIcon {
    transform: scale(0.7);
  }
  .StarButton--starred,
  .StarButton--starred:hover .StarIcon {
    animation: 650ms star-grow-rotate ease-out;
    fill: #AAF9F6;
    stroke: none;
  }

  .Star-selected {
    fill: #AAF9F6;
  }
  
  @keyframes star-grow-rotate {
    0% {
      transform: scale(1) rotate(0deg);
    }
  
    50% {
      transform: scale(1.5) rotate(120deg);
    }
  
    100% {
      /* 72deg is a rotation by one spike. The star has 5 spikes. 360deg / 5 = 72 */
      transform: scale(1) rotate(144deg);
    }
  }
  